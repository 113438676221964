import React, { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'wouter'
import { isJwtExpired } from '../services/jwtService'
import { useSetRecoilState } from 'recoil'
import { isLoggedInState } from '../state/authState'
import {
  loadPoleEventData,
  coronaLevelToCategory,
  surfaceDischargeLevelToCategory,
  arcingLevelToCategory,
  colorToHealthExplanation,
  loadSummarizedPoleData
} from '../services/poleServices'

import { PoleEventData } from '../types/poles/PoleData'

import CountUp from 'react-countup'
import LoadingOverlay from '../components/atoms/LoadingOverlay'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../components/ui/accordion'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select'

import { Label } from '../components/ui/label'
import { Checkbox } from '../components/ui/checkbox'
import { includes, concat, equals } from 'ramda'
import { useQuery, useQueries } from '@tanstack/react-query'
import EventLog from '../components/organisms/EventLog'

import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet.heat'
//delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

import { createCheckedMarkerIcon, createMarkerIcon } from '../services/iconService'
import { cn } from '../lib/utils'
import { X } from 'lucide-react'
import { Badge } from '../components/ui/badge'
import { AnalyticPagination } from '../components/AnalyticPagination'
import { Loader2 } from 'lucide-react'
import { TableSkeleton } from '../components/TableSkeleton'

import CorrelationPlayground from '../components/organisms/CorrelationPlayground'

const greyIcon = createMarkerIcon('grey')
const blueIcon = createMarkerIcon('blue')
const greenIcon = createMarkerIcon('green')
const yellowIcon = createMarkerIcon('yellow')
const orangeIcon = createMarkerIcon('orange')
const redIcon = createMarkerIcon('red')

const checkedGreyIcon = createCheckedMarkerIcon('grey')
const checkedBlueIcon = createCheckedMarkerIcon('blue')
const checkedGreenIcon = createCheckedMarkerIcon('green')
const checkedYellowIcon = createCheckedMarkerIcon('yellow')
const checkedOrangeIcon = createCheckedMarkerIcon('orange')
const checkedRedIcon = createCheckedMarkerIcon('red')

import PoleInfo from '../components/organisms/PoleInfo'
// import { usePoleData } from '../hooks/usePoleData'
import { PoleData } from '../types/poles/PoleData'

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '../components/ui/tabs'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../components/ui/table'
import { Button } from '../components/ui/button'

interface HeatmapLayerProps {
  points: [number, number, number][]
}

const HeatmapLayer: React.FC<HeatmapLayerProps> = ({ points }) => {
  const map = useMap()

  useEffect(() => {
    const heatLayer = L.heatLayer(points, { radius: 25, blur: 15 }).addTo(map)

    const updateHeatmap = () => {
      const zoom = map.getZoom()
      const radius = ((18 - zoom) * 2 + 1) * 25
      const blur = ((18 - zoom) * 2 + 1) * 15
      heatLayer.setOptions({ radius, blur })
      heatLayer.redraw()
    }

    map.on('zoomend', updateHeatmap)

    return () => {
      map.removeLayer(heatLayer)
      map.off('zoomend', updateHeatmap)
    }
  }, [map, points])

  return null
}

interface LoadedEvents {
  [id: number | string]: {
    loadedAt: Date
    events: PoleEventData[]
  }
}

const TransmissionLinePage = () => {
  const [loadedEventsByNodeId, setLoadedEventsByNodeId] =
    useState<LoadedEvents>({})
  const setIsLoggedIn = useSetRecoilState(isLoggedInState)
  const [, navigate] = useLocation()
  // Ensure logged in before hitting API. 
  useEffect(() => {
    const jwt = localStorage.getItem('authToken')
    if (null === jwt || isJwtExpired(jwt || '')) {
      localStorage.removeItem('authToken')
      setIsLoggedIn(false)
      navigate('/login')
    }
  }, [navigate])
  const { data: polesData, isLoading: loadingData } = useQuery({
    queryKey: ['poles'],
    queryFn: () => loadSummarizedPoleData({ clientId: 3 }),
    select: (data) => data?.nodes
  })
  const poles = polesData?.map((p: PoleData) => {
    p.mostRecentEvent.timestamp = new Date(p.mostRecentEvent.timestamp)
    p.mostRecentEvent.timestamp = new Date(
      p.mostRecentEvent.timestamp.getTime() +
      p.mostRecentEvent.timestamp.getTimezoneOffset() * 60000
    )
    return p
  }) || []
  const heatMapPoints: [number, number, number][] = poles.map((p) => {
    return [p.latitude, p.longitude, 0.0]
  })
  const [selectedPole, setSelectedPole] = useState<PoleData | null>(null)
  const [selectedPoles, setSelectedPoles] = useState<PoleData[]>([])
  const [aggLevel, setAggLevel] = useState('last30Days')
  const [filterCoronaLow, setFilterCoronaLow] = useState(false)
  const [filterCoronaMedium, setFilterCoronaMedium] = useState(false)
  const [filterCoronaHigh, setFilterCoronaHigh] = useState(false)
  const [filterSuspectedSurfaceDischarge, setFilterSuspectedSurfaceDischarge] =
    useState(false)
  const [filterConfirmedSurfaceDischarge, setFilterConfirmedSurfaceDischarge] =
    useState(false)
  const [filterSuspectedArcing, setFilterSuspectedArcing] = useState(false)
  const [filterConfirmedArcing, setFilterConfirmedArcing] = useState(false)
  const [filterVegetationType, setFilterVegetationType] = useState('all')
  const [enableMultiPoleAnalysis, setEnableMultiPoleAnalysis] = useState(false)
  const [activeTab, setActiveTab] = useState('map')

  const poleQueries = useQueries({
    queries: selectedPoles.map(pole => ({
      queryKey: ['pole-events', pole.id],
      queryFn: () => Promise.all([
        loadPoleEventData({
          poleId: pole.id,
          startDayStr: getDateNDaysAgo(15)
        }),
        loadPoleEventData({
          poleId: pole.id,
          startDayStr: getDateNDaysAgo(30),
          endDayStr: getDateNDaysAgo(15)
        })
      ]),
      enabled: selectedPoles.length > 0 && enableMultiPoleAnalysis
    }))
  })



  const isMultiEventsLoading = poleQueries.some(query => query.isLoading)
  const isFetched = poleQueries.every(query => query.isFetched)

  useEffect(() => {
    if (isFetched && enableMultiPoleAnalysis) {
      setEnableMultiPoleAnalysis(false)
    }
  }, [isFetched, enableMultiPoleAnalysis])

  useEffect(() => {
    if (enableMultiPoleAnalysis) {
      setActiveTab('analytics')
    }
  }, [enableMultiPoleAnalysis])

  const selectedPolesWithEvents = selectedPoles?.map((pole, i) => {
    const poleData = poleQueries[i]?.data || []
    return {
      ...pole,
      events: concat(poleData[0]?.events || [], poleData[1]?.events || [])
    }
  })

  const eventColors = {
    'Low Corona': '#00bf28',
    'Medium Corona': '#0055a9',
    'High Corona': '#5b17b0',
    'Suspected Surface Discharge': '#c3a400',
    'Confirmed Surface Discharge': '#ff7e00',
    'Suspected Arcing': '#ff10d7',
    'Confirmed Arcing': '#ff102b'
  }

  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0]
  }
  const getDateNDaysAgo = (daysAgo: number) => {
    return formatDate(new Date(Date.now() - daysAgo * 24 * 60 * 60 * 1000))
  }

  const seeMoreInfoForPole = async (pole: PoleData | null) => {
    if (null !== pole) {
      pole = { ...pole } // Using pole from selector. It's readonly. Make it writeable. Kinda hacky.
      const poleId = pole.id
      if (!(pole.id in loadedEventsByNodeId)) {
        // setLoadingData(true)
        // Need to load and set pole data.

        const start1 = getDateNDaysAgo(15)
        const start2 = getDateNDaysAgo(30)

        const eventData1 = await loadPoleEventData({
          poleId,
          startDayStr: start1
        })
        const eventData2 = await loadPoleEventData({
          poleId,
          startDayStr: start2,
          endDayStr: start1
        })
        const events = [
          ...(eventData1.events || []),
          ...(eventData2.events || [])
        ]
        setLoadedEventsByNodeId({
          ...loadedEventsByNodeId,
          [poleId]: { loadedAt: new Date(), events }
        })
        pole.events = events
        // setLoadingData(false)
      } else {
        pole.events = loadedEventsByNodeId[poleId].events
      }
    }
    setSelectedPole(pole)
  }

  const anyFiltersApplied =
    filterCoronaLow ||
    filterCoronaMedium ||
    filterCoronaHigh ||
    filterSuspectedSurfaceDischarge ||
    filterConfirmedSurfaceDischarge ||
    filterSuspectedArcing ||
    filterConfirmedArcing ||
    filterVegetationType !== 'all'

  let filteredPoles = poles
  if (anyFiltersApplied) {
    filteredPoles = poles.filter((pole) => {
      if (
        filterCoronaLow &&
        pole.coronaCounts[aggLevel] &&
        pole.coronaCounts[aggLevel][1] > 0
      ) {
        return true
      } else if (
        filterCoronaMedium &&
        pole.coronaCounts[aggLevel] &&
        pole.coronaCounts[aggLevel][2] > 0
      ) {
        return true
      } else if (
        filterCoronaHigh &&
        pole.coronaCounts[aggLevel] &&
        pole.coronaCounts[aggLevel][3] > 0
      ) {
        return true
      } else if (
        filterSuspectedSurfaceDischarge &&
        pole.surfaceDischargeCounts[aggLevel] &&
        pole.surfaceDischargeCounts[aggLevel][1] > 0
      ) {
        return true
      } else if (
        filterConfirmedSurfaceDischarge &&
        pole.surfaceDischargeCounts[aggLevel] &&
        pole.surfaceDischargeCounts[aggLevel][2] > 0
      ) {
        return true
      } else if (
        filterSuspectedArcing &&
        pole.arcingCounts[aggLevel] &&
        pole.arcingCounts[aggLevel][1] > 0
      ) {
        return true
      } else if (
        filterConfirmedArcing &&
        pole.arcingCounts[aggLevel] &&
        pole.arcingCounts[aggLevel][2] > 0
      ) {
        return true
      } else if (pole.vegetationTypes.indexOf(filterVegetationType) > -1) {
        return true
      }
      return false
    })
  }

  const getPoleHealth = (pole: PoleData, aggLevel: string): string => {
    if (!pole.coronaCounts[aggLevel]) {
      return 'grey'
    }
    let poleCondition = 'blue'
    if (
      pole.coronaCounts[aggLevel][1] > 0 &&
      pole.coronaCounts[aggLevel][2] <= 0 &&
      pole.coronaCounts[aggLevel][3] <= 0 &&
      pole.surfaceDischargeCounts[aggLevel][1] <= 0 &&
      pole.surfaceDischargeCounts[aggLevel][2] <= 0 &&
      pole.arcingCounts[aggLevel][1] <= 0 &&
      pole.arcingCounts[aggLevel][2] <= 0
    ) {
      poleCondition = 'green'
    }
    if (
      (pole.coronaCounts[aggLevel][2] > 0 ||
        pole.coronaCounts[aggLevel][3] > 0) &&
      pole.surfaceDischargeCounts[aggLevel][1] <= 0 &&
      pole.surfaceDischargeCounts[aggLevel][2] <= 0 &&
      pole.arcingCounts[aggLevel][1] <= 0 &&
      pole.arcingCounts[aggLevel][2] <= 0
    ) {
      poleCondition = 'yellow'
    }
    if (pole.surfaceDischargeCounts[aggLevel][1] > 0) {
      poleCondition = 'orange'
    }
    if (
      pole.surfaceDischargeCounts[aggLevel][2] > 0 ||
      pole.arcingCounts[aggLevel][1] > 0 ||
      pole.arcingCounts[aggLevel][2] > 0
    ) {
      poleCondition = 'red'
    }
    return poleCondition
  }

  const countStats = [
    {
      title: 'Poles Monitored',
      n: (poles || []).length
    },
    {
      title: 'Poles at High Risk',
      n: (poles || []).filter((pole) => {
        const toReturn =
          (pole.arcingCounts['last30Days'][1] > 0 ||
            pole.arcingCounts['last30Days'][2] > 0) &&
          true // TODO: Use actual wildfire risk data.
        return toReturn
      }).length
    },
    {
      title: 'Extreme Low Health Poles',
      n: (poles || []).filter((pole) => {
        return 'red' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [redIcon]
    },
    {
      title: 'Low Health Poles',
      n: (poles || []).filter((pole) => {
        return 'orange' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [orangeIcon]
    },
    {
      title: 'Moderately Healthy Poles',
      n: (poles || []).filter((pole) => {
        return 'yellow' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [yellowIcon]
    },
    {
      title: 'Mostly Healthy Poles',
      n: (poles || []).filter((pole) => {
        return 'green' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [greenIcon]
    },
    {
      title: 'Healthy Poles',
      n: (poles || []).filter((pole) => {
        return 'blue' === getPoleHealth(pole, aggLevel)
      }).length,
      icons: [blueIcon]
    }
  ]

  const [itemsPerPage, setItemsPerPage] = useState(100)
  const [currentPage, setCurrentPage] = useState(1)

  const allEvents = selectedPolesWithEvents
    ?.flatMap?.(pole => pole?.events || [])
    ?.sort?.((a, b) => b?.timestamp?.getTime() - a?.timestamp?.getTime()) || []

  const totalPages = Math.ceil((allEvents?.length || 0) / itemsPerPage)

  const paginatedEvents = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage
    const end = start + itemsPerPage
    return allEvents?.slice(start, end) || []
  }, [allEvents, currentPage, itemsPerPage])

  // Reset page when data or items per page changes
  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1)
    }
  }, [allEvents.length, totalPages, itemsPerPage])

  const handleItemsPerPageChange = (value: number) => {
    setItemsPerPage(value)
    setCurrentPage(1) // Reset to first page when changing items per page
  }

  const mapContent = (
    <div className={cn('my-6', { block: selectedPole === null && !loadingData })}>
      <MapContainer
        center={[53.3850725, -113.035714]}
        zoom={9}
        scrollWheelZoom={false}
        className="h-[60vh] w-full"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        />
        {false ? <HeatmapLayer points={heatMapPoints} /> : null}
        {filteredPoles.map((pole) => {
          const poleCondition = getPoleHealth(pole, aggLevel)
          const isSelected = includes(pole, selectedPoles)

          let icon = isSelected ? checkedBlueIcon : blueIcon
          if ('green' === poleCondition) {
            icon = isSelected ? checkedGreenIcon : greenIcon
          } else if ('yellow' === poleCondition) {
            icon = isSelected ? checkedYellowIcon : yellowIcon
          } else if ('orange' === poleCondition) {
            icon = isSelected ? checkedOrangeIcon : orangeIcon
          } else if ('red' === poleCondition) {
            icon = isSelected ? checkedRedIcon : redIcon
          } else if ('grey' === poleCondition) {
            icon = isSelected ? checkedGreyIcon : greyIcon
          }

          const mostRecentEvent = pole.mostRecentEvent
          const formattedTimeForMostRecentMeasurement =
            mostRecentEvent !== null
              ? mostRecentEvent.timestamp.toISOString().split('T')[0] +
              ' ' +
              mostRecentEvent.timestamp
                .toTimeString()
                .split(' ')[0]
                .slice(0, 5)
              : ''

          const safeGetCount = (
            count: { [k: string]: { [i: number]: number } },
            aggLevel: string,
            n: number
          ) => {
            if (!count) {
              return 0
            } else if (!count[aggLevel]) {
              return 0
            } else if (!count[aggLevel][n]) {
              return 0
            }
            return count[aggLevel][n] || 0
          }
          return (
            <Marker
              key={pole.id}
              position={[pole.latitude, pole.longitude]}
              icon={icon}
            >
              {mostRecentEvent !== null ? (
                <Popup>
                  <div className=''>
                    {colorToHealthExplanation(poleCondition)}
                  </div>
                  <div className='pt-2'>
                    <span className='font-bold'>
                      Most recent measurement (
                      {formattedTimeForMostRecentMeasurement}
                      ):
                    </span>
                    <ul className='list-disc pl-5'>
                      <li>
                        {coronaLevelToCategory(mostRecentEvent.coronaLevel)}
                      </li>
                      <li>
                        {surfaceDischargeLevelToCategory(
                          mostRecentEvent.surfaceDischargeLevel
                        )}
                      </li>
                      <li>
                        {arcingLevelToCategory(mostRecentEvent.arcingLevel)}
                      </li>
                    </ul>
                  </div>
                  <p>
                    {safeGetCount(pole.arcingCounts, aggLevel, 1) +
                      safeGetCount(pole.arcingCounts, aggLevel, 2)}
                    &nbsp;arcing events,&nbsp;
                    {safeGetCount(pole.surfaceDischargeCounts, aggLevel, 1) +
                      safeGetCount(pole.surfaceDischargeCounts, aggLevel, 2)}
                    &nbsp;surface discharge / surface tracking events,
                    and&nbsp;
                    {safeGetCount(pole.coronaCounts, aggLevel, 1) +
                      safeGetCount(pole.coronaCounts, aggLevel, 2) +
                      safeGetCount(pole.coronaCounts, aggLevel, 3)}
                    &nbsp;corona events detected in selected period.
                  </p>
                  <div className='flex justify-between'>
                    <Button
                      variant='outline'
                      onClick={() => seeMoreInfoForPole(pole)}
                      className=' px-4 py-2'
                    >
                      See more
                    </Button>
                    <Label className='inline-flex items-center'>
                      <Checkbox
                        checked={includes(pole, selectedPoles)}
                        onCheckedChange={(checked) => {
                          setSelectedPoles(poles =>
                            checked ? [...poles, pole] : poles.filter(p => p.id !== pole.id)
                          )
                        }}
                      />
                      <span className='ml-2'>Select for analysis</span>
                    </Label>
                  </div>
                  {poleCondition === 'red' ? (
                    <p className='font-bold'>
                      Given environmental conditions and pole activity, this
                      pole is at significant risk.
                    </p>
                  ) : null}
                </Popup>
              ) : null}
            </Marker>
          )
        })}
      </MapContainer>
    </div>
  )

  const allReadingsContent = (
    <div className="rounded-md border">
      <div className="relative">
        {isMultiEventsLoading && (
          <div className="absolute inset-0 bg-background/50 backdrop-blur-sm flex items-center justify-center z-50">
            <Loader2 className="h-8 w-8 animate-spin text-primary" />
          </div>
        )}
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Pole</TableHead>
              <TableHead>Date & Time</TableHead>
              <TableHead>Corona Severity</TableHead>
              <TableHead>Surface Discharge Severity</TableHead>
              <TableHead>Arcing Severity</TableHead>
              <TableHead>Temperature</TableHead>
              <TableHead>Humidity</TableHead>
              <TableHead>Pressure</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isMultiEventsLoading ? (
              <TableSkeleton rows={itemsPerPage} columns={8} />
            ) : (
              paginatedEvents?.map?.((event, i) => {
                const formattedDate = event?.timestamp?.toISOString()?.split('T')[0] + ' ' +
                  event?.timestamp?.toTimeString()?.split(' ')[0]?.slice(0, 5)
                const pole = selectedPoles.find(p => p.id === event.poleId)
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {pole ? `${pole.info.lineNumber}-${pole.info.poleNumber}` : event.poleId}
                    </TableCell>
                    <TableCell>{formattedDate}</TableCell>
                    <TableCell>{coronaLevelToCategory(event.coronaLevel)}</TableCell>
                    <TableCell>{surfaceDischargeLevelToCategory(event.surfaceDischargeLevel)}</TableCell>
                    <TableCell>{arcingLevelToCategory(event.arcingLevel)}</TableCell>
                    <TableCell>{event.temperature}°C</TableCell>
                    <TableCell>{event.humidity}%</TableCell>
                    <TableCell>{event.pressure} Pa</TableCell>
                  </TableRow>
                )
              })
            )}
          </TableBody>
        </Table>

        <div className="py-4">
          <AnalyticPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            totalItems={allEvents.length}
          />
        </div>
      </div>
    </div>
  )

  const analyticsContent = (
    <Tabs defaultValue="all-readings" className="w-full">
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="all-readings">All Readings </TabsTrigger>
        <TabsTrigger value="event-graph">Event Graph</TabsTrigger>
        <TabsTrigger value="correlation">Correlation Playground</TabsTrigger>
      </TabsList>

      <TabsContent value="all-readings" className="border rounded-lg p-4">
        {allReadingsContent}
      </TabsContent>

      <TabsContent value="event-graph" className="border rounded-lg p-4">
        <EventLog events={allEvents} eventColors={eventColors} />
      </TabsContent>

      <TabsContent value="correlation" className="border rounded-lg p-4">
        <CorrelationPlayground events={allEvents} eventColors={eventColors} />
      </TabsContent>
    </Tabs>
  )

  return (
    <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <LoadingOverlay enabled={loadingData} />
      <div className='flex flex-wrap justify-center gap-4'>
        {countStats.map((item, index) => (
          <div key={index} className='flex flex-col items-center p-4'>
            <div className='flex items-center space-x-2'>
              {item.icons && item.icons.length > 0 && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: String(item.icons[0].options.html)
                  }}
                ></span>
              )}
              <CountUp
                start={0}
                end={item.n}
                duration={2.5}
                className='text-3xl font-bold'
              />
            </div>
            <span className='text-sm text-gray-600'>{item.title}</span>
          </div>
        ))}
      </div>

      <div className="my-6">
        <Accordion type="single" collapsible className="w-full z-50 border rounded-lg shadow">
          <AccordionItem value="item-1">
            <AccordionTrigger className="text-lg font-medium px-4">Filters</AccordionTrigger>
            <AccordionContent className="p-8 pt-4 grid grid-cols-1  md:grid-cols-2 gap-12">
              <div className="space-y-8">
                <div>
                  <Label htmlFor="vegetation">Vegetation Type</Label>
                  <Select value={filterVegetationType} onValueChange={(value) => setFilterVegetationType(value)}>
                    <SelectTrigger className="w-full mt-2">
                      <SelectValue placeholder="Select vegetation type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All</SelectItem>
                      <SelectItem value="shrubs">Shrubs</SelectItem>
                      <SelectItem value="grass">Grass</SelectItem>
                      <SelectItem value="deciduous">Deciduous Forest</SelectItem>
                      <SelectItem value="mixedwood">Mixed Wood</SelectItem>
                      <SelectItem value="uplandconnonpine">Upland Coniferous (Non-pine)</SelectItem>
                      <SelectItem value="lowlandconblackspruce">Lowland Coniferous (Black Spruce)</SelectItem>
                      <SelectItem value="lowlandconlarch">Lowland Coniferous (Larch)</SelectItem>
                      <SelectItem value="shrubbywetlands">Non-treed Shrubby Wetlands</SelectItem>
                      <SelectItem value="openwetlands">Non-treed Open Wetlands</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="aggregation">Aggregate over</Label>
                  <Select value={aggLevel} onValueChange={(value) => setAggLevel(value)}>
                    <SelectTrigger className="w-full mt-2">
                      <SelectValue placeholder="Select period" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="last24Hours">Last 24 Hours</SelectItem>
                      <SelectItem value="last7Days">Last 7 Days</SelectItem>
                      <SelectItem value="last30Days">Last 30 Days</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div>
                <Label className="mb-4 block">Must include</Label>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="confirmedArcing"
                      checked={filterConfirmedArcing}
                      onCheckedChange={(checked) => setFilterConfirmedArcing(!!checked)}
                    />
                    <Label htmlFor="confirmedArcing" className="text-sm font-normal">Confirmed Arcing</Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="suspectedArcing"
                      checked={filterSuspectedArcing}
                      onCheckedChange={(checked) => setFilterSuspectedArcing(!!checked)}
                    />
                    <Label htmlFor="suspectedArcing" className="text-sm font-normal">Suspected Arcing</Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="confirmedSurfaceDischarge"
                      checked={filterConfirmedSurfaceDischarge}
                      onCheckedChange={(checked) => setFilterConfirmedSurfaceDischarge(!!checked)}
                    />
                    <Label htmlFor="confirmedSurfaceDischarge" className="text-sm font-normal">
                      Confirmed Surface Discharge
                    </Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="suspectedSurfaceDischarge"
                      checked={filterSuspectedSurfaceDischarge}
                      onCheckedChange={(checked) => setFilterSuspectedSurfaceDischarge(!!checked)}
                    />
                    <Label htmlFor="suspectedSurfaceDischarge" className="text-sm font-normal">
                      Suspected Surface Discharge
                    </Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="highCorona"
                      checked={filterCoronaHigh}
                      onCheckedChange={(checked) => setFilterCoronaHigh(!!checked)}
                    />
                    <Label htmlFor="highCorona" className="text-sm font-normal">High Corona</Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="mediumCorona"
                      checked={filterCoronaMedium}
                      onCheckedChange={(checked) => setFilterCoronaMedium(!!checked)}
                    />
                    <Label htmlFor="mediumCorona" className="text-sm font-normal">Medium Corona</Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="lowCorona"
                      checked={filterCoronaLow}
                      onCheckedChange={(checked) => setFilterCoronaLow(!!checked)}
                    />
                    <Label htmlFor="lowCorona" className="text-sm font-normal">Low Corona</Label>
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      <div className='border rounded-lg shadow p-4'>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold">Selected poles</h2>
          <div className="space-x-2">
            <Button
              variant="default"
              disabled={!selectedPoles.length || isMultiEventsLoading}
              onClick={() => setEnableMultiPoleAnalysis(true)}
            >
              Analyze poles
            </Button>
            <Button
              variant="outline"
              onClick={() => setSelectedPoles([])}
            >
              Clear all
            </Button>
          </div>
        </div>
        <div className="">
          <div className="flex flex-wrap gap-2">
            {selectedPoles.length === 0 ? (
              <p className="text-sm text-gray-500">No poles selected</p>
            ) : (
              selectedPoles.map((pole) => (
                <Badge
                  key={pole.id}
                  variant="secondary"
                  className="flex items-center gap-2 px-3 py-1.5"
                >
                  <span>
                    Pole {pole.info.lineNumber}-{pole.info.poleNumber}
                  </span>
                  <button
                    onClick={() => {
                      setSelectedPoles(poles =>
                        poles.filter(p => p.id !== pole.id)
                      )
                    }}
                    className="hover:bg-gray-200 rounded-full p-0.5"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </Badge>
              ))
            )}
          </div>
        </div>
      </div>

      <div className="my-6">
        <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="map">Map</TabsTrigger>
            <TabsTrigger value="analytics" className="flex items-center gap-2">
              {isMultiEventsLoading
                ? <Loader2 className="h-4 w-4 animate-spin text-primary" />
                : <div></div>
              } Analytics
            </TabsTrigger>
          </TabsList>

          <TabsContent value="map" className="relative z-0 border rounded-lg p-4">
            {mapContent}
          </TabsContent>
          <TabsContent value="analytics" className="relative z-0 border rounded-lg p-4">
            {analyticsContent}
          </TabsContent>
        </Tabs>
      </div>

      {selectedPole && (
        <div className='fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-auto'>
          <div className='max-w-[95vw] max-h-[85vh] w-full h-full bg-white p-8 rounded shadow-lg z-50'>
            <div className='w-full h-full overflow-auto'>
              <div className='flex justify-between'>
                <button
                  onClick={() => seeMoreInfoForPole(null)}
                  className='bg-red-500 hover:bg-red-800 text-white rounded px-4 py-2'
                >
                  Close
                </button>
                <div className='mx-4'>
                  Information for Pole {selectedPole.info.lineNumber}-
                  {selectedPole.info.poleNumber} ({selectedPole.deviceId}) at (
                  {selectedPole.latitude},{selectedPole.longitude})
                </div>
              </div>
              <PoleInfo pole={selectedPole} eventColors={eventColors} />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TransmissionLinePage
