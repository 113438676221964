import axios, { AxiosResponse, isAxiosError } from 'axios'
import { PoleEventData, PoleData } from '../types/poles/PoleData'

const API_BASE_URL = process.env.REACT_APP_SEVERLESS_BASE_URL
//const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

interface SummarizedPolesApiResult {
  data: {
    message: string
    nodes: PoleData[]
  }
}

export const loadSummarizedPoleData = async (params: {
  [key: string]: unknown
}) => {
  const requestUrl = `${API_BASE_URL}/pdata`
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${localStorage.getItem('authToken')}`
    }
  }
  try {
    const response: AxiosResponse<SummarizedPolesApiResult> = await axios.post(
      requestUrl,
      params,
      config
    )
    return response.data.data
  } catch (error: unknown) {
    if (isAxiosError(error) && error?.response?.data?.error) {
      throw new Error(error.response.data.error)
    } else {
      throw error
    }
  }
}

interface PoleEventApiResult {
  data: {
    message: string
    events: PoleEventData[]
  }
}

export const loadPoleEventData = async (params: { [key: string]: unknown }) => {
  const requestUrl = `${API_BASE_URL}/edata`
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${localStorage.getItem('authToken')}`
    }
  }
  try {
    const response: AxiosResponse<PoleEventApiResult> = await axios.post(
      requestUrl,
      params,
      config
    )
    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.events
    ) {
      response.data.data.events = response.data.data.events.map(
        (e: PoleEventData) => {
          e.timestamp = new Date(e.timestamp)
          e.timestamp = new Date(
            e.timestamp.getTime() + e.timestamp.getTimezoneOffset() * 60000
          )
          return e
        }
      )
    }
    return response.data.data
  } catch (error: unknown) {
    if (isAxiosError(error) && error?.response?.data?.error) {
      throw new Error(error.response.data.error)
    } else {
      throw error
    }
  }
}

export const coronaLevelToShortCategory = (level: number): string => {
  if (level === 1) {
    return 'Low'
  } else if (level === 2) {
    return 'Medium'
  } else if (level === 3) {
    return 'High'
  }
  return 'None'
}

export const coronaLevelToCategory = (level: number): string => {
  const shortCategory = coronaLevelToShortCategory(level)
  if (shortCategory === 'None') {
    return 'No Corona'
  }
  return `${shortCategory} Corona`
}

export const surfaceDischargeLevelToShortCategory = (level: number): string => {
  if (level === 1) {
    return 'Suspected'
  } else if (level === 2) {
    return 'Confirmed'
  }
  return 'None'
}

export const surfaceDischargeLevelToCategory = (level: number): string => {
  const shortCategory = surfaceDischargeLevelToShortCategory(level)
  if (shortCategory === 'None') {
    return 'No Surface Discharge'
  }
  return `${shortCategory} Surface Discharge`
}

export const arcingLevelToShortCategory = surfaceDischargeLevelToShortCategory

export const arcingLevelToCategory = (level: number): string => {
  const shortCategory = arcingLevelToShortCategory(level)
  if (shortCategory === 'None') {
    return 'No Arcing'
  }
  return `${shortCategory} Arcing`
}

export const colorToHealth = (color: string): string => {
  if (color === 'blue') {
    return 'Healthy'
  } else if (color === 'green') {
    return 'Mostly Healthy'
  } else if (color === 'yellow') {
    return 'Moderate Health'
  } else if (color === 'orange') {
    return 'Low Health'
  } else if (color === 'red') {
    return 'Extreme Low Health'
  }
  return 'Health Unknown'
}

export const colorToHealthExplanation = (color: string): string => {
  if (color === 'blue') {
    return 'This pole is experiencing no discharge activity and is considered totally healthy (blue).'
  } else if (color === 'green') {
    return 'This pole is experiencing a low amount of corona, but is still mostly healthy (green).'
  } else if (color === 'yellow') {
    return 'This pole is experiencing a moderate to high degree of corona, but shows no surface discharge / surface tracking or arcing, and is therefore moderately healthy (yellow).'
  } else if (color === 'orange') {
    return 'This pole is experiencing suspected surface discharge / surface tracking. It is in poor health (orange).'
  } else if (color === 'red') {
    return 'This pole has confirmed arcing and/or surface discharge / surface tracking, and is therefore in extremely poor health (red).'
  }
  return 'The health status of this pole is currently unknown - check back later.'
}
